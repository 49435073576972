<template>
  <div class="upload">
    <h2>我的上传</h2>
    <a-menu :selected-keys="[current]" mode="horizontal" @click="onChange">
      <a-menu-item key="all">全部</a-menu-item>
      <a-menu-item :key="2">已通过</a-menu-item>
      <a-menu-item :key="1">未通过</a-menu-item>
      <a-menu-item :key="0">审核中</a-menu-item>
    </a-menu>
    <div class="upload-btn" @click="handleUpload">
      <img src="@/assets/image/user_upload_icon.png" alt="" />上传
    </div>
    <div class="upload-list">
      <a-table :columns="columns" :data-source="list" :pagination="false" rowKey="id">
        <span slot="fileName" :title="text" slot-scope="text, record">
          <img
            src="@/assets/image/boutique_icon_word.png"
            v-if="record.fileTypeName === 'word'"
          />
          <img
            src="@/assets/image/boutique_icon_ppt.png"
            v-else-if="record.fileTypeName === 'ppt'"
          />
          <img
            src="@/assets/image/boutique_icon_pdf.png"
            v-else-if="record.fileTypeName === 'pdf'"
          />
          <img
            src="@/assets/image/boutique_icon_video.png"
            v-else-if="record.fileTypeName === 'video'"
          />
          <img
            src="@/assets/image/boutique_icon_zip.png"
            v-else-if="record.fileTypeName === 'package'"
          />
          <img
            src="@/assets/image/boutique_icon_other.png"
            v-else-if="record.fileTypeName === 'other'"
          />
          {{ text }}
        </span>
        <span slot="fileSize" slot-scope="text">
          {{ text | handleSize }}
        </span>
        <span
          slot="status"
          slot-scope="text"
          :style="{ color: text === 0 ? '#ed6f21' : text === 1 ? '#F5222D' : '#4093FF' }"
        >
          {{ text | handleStatus }}
        </span>
        <span slot="action" slot-scope="text, record">
          <a @click="backFile(record)" v-if="record.status === 0">撤销</a>
          <a-popover
            placement="bottom"
            :visible="record.recordId === showId"
            trigger="click"
            @visibleChange="(e) => handleVisibleChange(e, record.recordId)"
            v-else-if="record.status === 1"
          >
            <span slot="content">{{ record.statusRemark }}</span>
            <a>查看原因</a>
          </a-popover>
          <a @click="toPreview(record)" v-else-if="record.status === 2">查看详情</a>
        </span>
      </a-table>
      <div class="pagination-container">
        <a-pagination
          v-model="pageIndex"
          :pageSize="pageSize"
          :default-current="1"
          :total="total"
          :hideOnSinglePage="true"
          showLessItems
          @change="onChangePage"
          :item-render="itemRender"
          class="pagination-content"
        />
      </div>
    </div>

    <!--上传弹出框-->
    <a-modal
      v-model="open"
      title="上传文件"
      okText="提交审核"
      @ok="submit"
      @cancel="cancel"
      :confirmLoading="loading"
    >
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        class="form-model"
      >
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="类别" prop="type">
              <a-select
                v-model="form.type"
                placeholder="请选择文件类别"
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                @change="changeType"
                :disabled="cannotSelect"
              >
                <a-select-option :value="item.id" v-for="item in typeList" :key="item.id"
                  >{{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="学科" prop="subject">
              <a-select
                v-model="form.subject"
                placeholder="请先选择类别再选择学科"
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                @change="changeSubject"
                :disabled="cannotSelect"
              >
                <a-select-option
                  :value="item.id"
                  v-for="item in subjectList"
                  :key="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="学段" prop="grade">
              <a-select
                v-model="form.grade"
                placeholder="请先选择学科再选择学段"
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                @change="changeGrade"
                :disabled="cannotSelect"
              >
                <a-select-option
                  :value="item.id"
                  v-for="item in gradeList"
                  :key="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="等级" prop="level">
              <a-select
                v-model="form.level"
                placeholder="请选择等级"
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                @change="changeLevel"
                :disabled="cannotSelect"
              >
                <a-select-option :value="0"> 精品资源 </a-select-option>
                <a-select-option :value="1"> 普通资源 </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <div class="finish-edit d-f f-d a-c" v-if="finishEdit">
          <img
            src="@/assets/image/user_download_list_icon_word.png"
            v-if="fileNameLast === 'doc' || fileNameLast === 'docx'"
          />
          <img
            src="@/assets/image/user_download_list_icon_pdf.png"
            v-else-if="fileNameLast === 'pdf'"
          />
          <img
            src="@/assets/image/user_download_list_icon_ppt.png"
            v-else-if="fileNameLast === 'ppt' || fileNameLast === 'pptx'"
          />
          <img
            src="@/assets/image/user_download_list_icon_video.png"
            v-else-if="fileNameLast === 'mp4'"
          />
          <img
            src="@/assets/image/user_download_list_icon_zip.png"
            v-else-if="fileNameLast === 'zip'"
          />
          <img src="@/assets/image/user_download_list_icon_other.png" v-else />
          <div class="title">
            {{ fileList[0].name }}
          </div>
          <div class="size">大小：{{ fileList[0].size | handleSize }}</div>
          <a-progress :percent="percent" strokeColor="#05ae44" v-if="percent != 0" />
        </div>
        <a-upload-dragger
          :file-list="fileList"
          :remove="handleRemove"
          :before-upload="beforeUpload"
          @change="handleChange"
          v-else
          :disabled="canUpload"
        >
          <img src="@/assets/image/user_upload_file.png" alt="" />
          <p class="file-push">请将文件拖拽到这里或者点击上传</p>
          <p class="file-size" v-if="canLitmit">文件大小不超过{{ litmitFileSize }}MB</p>
          <p class="file-size">必须上传{{ litmitFileType }}文件格式</p>
        </a-upload-dragger>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "标题",
    dataIndex: "fileName",
    width: "35%",
    align: "center",
    scopedSlots: { customRender: "fileName" },
    ellipsis: true,
  },
  {
    title: "上传时间",
    dataIndex: "createTime",
    width: "20%",
    align: "center",
  },
  {
    title: "大小",
    dataIndex: "fileSize",
    width: "15%",
    align: "center",
    scopedSlots: { customRender: "fileSize" },
  },
  {
    title: "状态",
    dataIndex: "status",
    width: "10%",
    align: "center",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "操作",
    dataIndex: "action",
    width: "20%",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
import Client from "@/utils/oss";
export default {
  data() {
    return {
      current: "all",
      status: null,
      pageIndex: 1,
      pageSize: 4,
      total: null,
      list: [],
      columns,
      open: false,
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      form: {},
      rules: {
        type: [
          {
            required: true,
            message: "请选择文件类别",
            trigger: "change",
          },
        ],
        subject: [
          {
            required: true,
            message: "请选择学科",
            trigger: "change",
          },
        ],
        grade: [
          {
            required: true,
            message: "请选择学段",
            trigger: "change",
          },
        ],
        level: [
          {
            type: "number",
            required: true,
            message: "请选择等级",
            trigger: "change",
          },
        ],
      },
      typeList: [],
      subjectList: [],
      gradeList: [],
      dataObj: null,
      dir: "",
      subjectName: "",
      gradeName: "",
      loading: false,
      litmitFileSize: 30,
      fileList: [],
      finishEdit: false,
      percent: 0,
      canUpload: true,
      showId: 0,
      canLitmit: false,
      isTypeArr: [],
      litmitFileType: "word或者pdf",
      cannotSelect: false,
      fileNameLast: "",
    };
  },
  filters: {
    handleSize(val) {
      if (val != null) {
        let KB = val / 1024;
        let KBInt = Math.floor(KB);
        if (KBInt > 1024) {
          let MB = KB / 1024;
          let MBInt = Math.floor(MB);
          if (MBInt > 1024) {
            let GB = MB / 1024;
            return GB.toFixed(2) + "G";
          } else {
            return MB.toFixed(2) + "M";
          }
        } else {
          return KB.toFixed(2) + "KB";
        }
      }
    },
    handleStatus(val) {
      return ["审核中", "未通过", "已通过"][val];
    },
  },
  methods: {
    //menu的切换
    onChange(item) {
      this.current = item.key;
      this.list = [];
      this.pageIndex = 1;
      if (this.current === "all") {
        this.status = null;
      } else {
        this.status = this.current;
      }
      this.getUploadList();
    },
    //分页器改变
    onChangePage(page, pageSize) {
      this.pageIndex = page;
      this.getUploadList();
    },
    //改变分页上一步下一步的文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a class="next">下一页</a>;
      }
      return originalElement;
    },
    //上传按钮
    handleUpload() {
      this.open = true;
    },
    //文件类型改变
    changeType() {
      delete this.form.subject;
      delete this.form.grade;

      //试题或教案
      if (this.form.type === 2 || this.form.type === 3) {
        this.litmitFileSize = 30;
        this.canLitmit = true;
        this.isTypeArr = ["pdf", "doc", "docx"];
        this.litmitFileType = "word或者pdf";
      }
      //ppt教案
      else if (this.form.type === 4) {
        this.litmitFileSize = 30;
        this.canLitmit = true;
        this.isTypeArr = ["ppt", "pptx"];
        this.litmitFileType = "ppt";
      }
      //视频
      else if (this.form.type === 71) {
        this.litmitFileSize = null;
        this.canLitmit = false;
        this.isTypeArr = ["mp4"];
        this.litmitFileType = "mp4";
      }
      //教材
      else if (this.form.type === 72) {
        this.litmitFileSize = null;
        this.canLitmit = false;
        this.isTypeArr = ["zip"];
        this.litmitFileType = "zip";
      }

      this.subjectList = this.typeList.find((r) => r.id === this.form.type).children;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.canUpload = false;
        } else {
          this.canUpload = true;
        }
      });
    },
    //学科改变
    changeSubject() {
      delete this.form.grade;
      this.gradeList = this.subjectList.find((r) => r.id === this.form.subject).children;
      this.subjectName = this.subjectList.find((r) => r.id === this.form.subject).name;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.canUpload = false;
        } else {
          this.canUpload = true;
        }
      });
    },
    //学段改变
    changeGrade() {
      this.gradeName = this.gradeList.find((r) => r.id === this.form.grade).name;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.canUpload = false;
        } else {
          this.canUpload = true;
        }
      });
    },
    //等级改变
    changeLevel() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.canUpload = false;
        } else {
          this.canUpload = true;
        }
      });
    },
    //提交审核
    submit() {
      this.cannotSelect = true;
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.fileList.length > 0) {
            this.loading = true;
            let type = "";
            if (this.form.type === 2) {
              type = "testQuestions";
            } else if (this.form.type === 3) {
              type = "teachingPlan";
            } else if (this.form.type === 4) {
              type = "ppt";
            } else if (this.form.type === 71) {
              type = "video";
            } else if (this.form.type === 72) {
              type = "teachingMaterial";
            }
            //获取Token

            let res = await this.$api.getSts({ type });
            const { Bucket, AccessKeyId, AccessKeySecret, SecurityToken } = res.data;
            this.dataObj = {
              region: "oss-cn-shanghai",
              bucket: Bucket,
              accessKeyId: AccessKeyId,
              accessKeySecret: AccessKeySecret,
              stsToken: SecurityToken,
            };
            this.dir = res.data.dir;

            let files = this.fileList[0];
            let navId = this.form.grade;
            let isBoutique = "";
            if (this.form.level === 0) {
              isBoutique = true;
            } else if (this.form.level === 1) {
              isBoutique = false;
            }

            const file = files;
            //修改命名
            var fileExtension = file.name;
            let date = new Date().getTime();
            let realName = date + "_" + fileExtension;
            const random_name =
              this.dir + this.subjectName + "/" + this.gradeName + "/" + realName;

            Client(this.dataObj)
              .multipartUpload(random_name, file, {
                progress: (p) => {
                  //获取进度条的值
                  this.percent = Math.floor(p * 100);
                },
              })
              .then((result) => {
                if (result.res.statusCode === 200) {
                  let params = {
                    navId,
                    isBoutique,
                    filePath: random_name,
                    fileName: fileExtension,
                    fileSize: file.size,
                  };

                  this.$api.uploadFile(params).then((res) => {
                    if (res.code === 200) {
                      this.$message.success("上传成功");
                      this.cancel();
                      this.getUploadList();
                    } else {
                      this.$message.error(res.message);
                      this.loading = false;
                    }
                  });
                }
              })
              .catch((error) => {
                this.$message.error(error);
                this.loading = false;
              });
          } else {
            this.$message.error("请上传文件");
          }
        }
      });
    },
    //取消
    cancel() {
      this.open = false;
      this.form = {};
      this.subjectList = [];
      this.gradeList = [];
      this.fileList = [];
      this.finishEdit = false;
      this.loading = false;
      this.canUpload = true;
      this.canLitmit = false;
      this.isTypeArr = [];
      this.litmitFileType = "word或者pdf";
      this.cannotSelect = false;
      this.percent = 0;
      this.fileNameLast = "";
    },
    //文件上传前的操作
    beforeUpload(file) {
      let litmitFileSize = this.litmitFileSize;
      let isTypeArr = this.isTypeArr;
      let canUpload = !file.name.includes("/");
      let fileName = file.name;
      let index = fileName.lastIndexOf(".");
      let lastName = fileName.substring(index + 1);

      if (canUpload) {
        if (isTypeArr.indexOf(lastName) != -1) {
          //教材或者视频
          if (litmitFileSize == null) {
            this.fileList = [...this.fileList, file];
          } else {
            let isLimitSize = file.size / 1024 / 1024 < litmitFileSize;
            if (isLimitSize) {
              this.fileList = [...this.fileList, file];
              let i = this.fileList[0].name.lastIndexOf(".");
              this.fileNameLast = this.fileList[0].name.substr(i + 1);
            } else {
              this.$message.error("您上传的文件超过限制");
            }
          }
        } else {
          this.$message.error("请上传正确的文件格式");
        }
      } else {
        this.$message.error("文件名不能含有'/'");
      }

      return false;
    },
    //删除文件列表
    handleRemove() {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    //上传文件改变时的状态
    handleChange(info) {
      if (this.fileList.length > 0) {
        this.finishEdit = true;
      } else {
        this.finishEdit = false;
      }
    },
    //撤消
    backFile(record) {
      this.$confirm({
        title: "您确认撤销吗",
        onOk: () => {
          this.$api.backFile(record.recordId).then((res) => {
            if (res.code === 200) {
              this.$message.success("撤销成功");
              this.getUploadList();
            } else {
              this.$message.error(res.msg);
            }
          });
        },
      });
    },
    //去三级详情
    toPreview(record) {
      this.$router.push({
        path: "/preview",
        query: { navId: record.navId, id: record.id },
      });
    },
    //上传列表
    getUploadList() {
      this.$api
        .getUploadList({
          pageSize: this.pageSize,
          pageNum: this.pageIndex,
          status: this.status,
        })
        .then((res) => {
          if (res.code === 200) {
            this.list = res.rows;
            this.total = res.total;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    //气泡卡片的显示隐藏
    handleVisibleChange(visible, num) {
      if (visible) {
        this.showId = num;
      } else {
        this.showId = 0;
      }
    },
  },
  created() {
    let navList = this.$ls.get("navList");
    this.typeList = navList;
    this.getUploadList();
  },
};
</script>

<style scoped lang="less">
@main-color: #05ae44;
.upload {
  width: 936px;
  height: 710px;
  background: #fff;
  padding: 24px 36px 0;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  h2 {
    font-size: 16px;
    font-weight: bold;
    line-height: 21px;
    color: #333333;
    margin-bottom: 24px;
  }
  .upload-btn {
    position: absolute;
    right: 36px;
    top: 90px;
    font-size: 14px;
    color: @main-color;
    cursor: pointer;
    img {
      width: 16px;
      height: 16px;
      margin-right: 6px;
      margin-top: -2px;
    }
  }
  .upload-list {
    width: 100%;
    box-sizing: border-box;
    padding-top: 24px;
    img {
      width: 16px;
    }

    .pagination-container {
      margin-top: 24px;
      width: 100%;

      .pagination-content {
        flex: 1;
        text-align: right;

        .ant-pagination-prev a {
          text-decoration: none;
          display: inline-block;
          width: 65px;
          height: 33px;
          border: 1px solid #d9dee5;
          border-radius: 2px;
        }

        .ant-pagination-next .next {
          text-decoration: none;
          display: inline-block;
          width: 65px;
          height: 33px;
          border-radius: 2px;
          border: none;
          background-color: @main-color;
          color: #fff;
        }
      }
    }
  }
}
::v-deep .ant-modal {
  width: 672px !important;
}
.form-model {
  padding: 10px 30px;
  .file-push {
    font-size: 12px;
    color: #999999;
    margin-bottom: 12px;
  }
  .file-size {
    font-size: 12px;
    color: #ed6f21;
    margin-bottom: 22px;
  }
  .finish-edit {
    width: 100%;
    color: #999999;
    font-size: 12px;
    img {
      width: 52px;
      height: 66px;
    }
    .title {
      width: 312px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-top: 16px;
      text-align: center;
    }
    .size {
      margin-top: 12px;
    }
  }
}
</style>
